import { createGlobalStyle } from 'styled-components';

import JungkaMediumWOFF from '../assets/fonts/Jungka-Medium.woff';
import JungkaMediumWOFF2 from '../assets/fonts/Jungka-Medium.woff2';

import JungkaBoldWOFF from '../assets/fonts/Jungka-Bold.woff';
import JungkaBoldWOFF2 from '../assets/fonts/Jungka-Bold.woff2';

export default createGlobalStyle`
  @font-face {
    font-family: 'Jungka-Medium';
    src: url('${JungkaMediumWOFF}') format('woff'),
      url('${JungkaMediumWOFF2}') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Jungka-Bold';
    src: url('${JungkaBoldWOFF}') format('woff'),
      url('${JungkaBoldWOFF2}') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  * {
    margin: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  ::-moz-selection { background: rgb(0, 0, 0); }
  ::selection { background: rgb(0, 0, 0); }

  html {
    font-size: 62.5%;
    font-family: 'Source Serif Pro', serif;
    font-weight: 400;
  }

  html, body, #root {
    position: relative;
    padding: 0;
    width: 100%;
    height: 100%;
    background: white;
    color: black;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    position: relative;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  div, footer, main, ol, ul, article, blockquote {
    position: relative;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }

  a:hover {
    background: rgba(0, 0, 0, 0.06);
  }

  a:focus {
    background: rgba(0, 0, 0, 0.2);
    outline: none;
  }

  p {
    margin: 0.6em 0;
  }
  h1, h2, h3, h4, h5, h6, blockquote {
    text-rendering: optimizeLegibility;
    font-weight: 400;
    margin: 0.6em 0;
  }
`;
