import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import GlobalStyle from './lib/GlobalStyle';

import ReactGA from 'react-ga';
ReactGA.initialize('UA-152047467-1');

ReactDOM.render(<>
  <GlobalStyle />
  <App />
</>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
