export default {
  firstName: 'Robert',
  surname: 'Bodnar',
  portfolio: 'Portfolio ⤓',
  biographie: 'Biography ⤓',
  instagram: 'Instagram',
  mail: 'studio@robertbodnar.com',
  address: {
    name: 'Robert Bodnar',
    street: 'Leopoldsgasse 16/G',
    postcode: '1020',
    city: 'Wien',
    country: 'Austria'
  },
};
