import React, { useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import Text from './text/Text';
import Portfolio from './assets/portfolio/RobertBodnar_portfolio.pdf';
import Biographie from './assets/portfolio/RobertBodnar_Biography.pdf';
import BG1 from './assets/images/bg1.jpg';
import BG2 from './assets/images/bg2.jpg';
import BG3 from './assets/images/bg3.jpg';
import BG4 from './assets/images/bg4.jpg';
import BG5 from './assets/images/bg5.jpg';
import BG6 from './assets/images/bg6.jpg';
import BG7 from './assets/images/bg7.jpg';
import BG8 from './assets/images/bg8.jpg';
import BG9 from './assets/images/bg9.jpg';

const images = [ BG1, BG2, BG3, BG4, BG5, BG6, BG7, BG8, BG9 ]

const BACKGROUND = images[Math.floor(Math.random() * images.length)];

window.addEventListener('touchstart', function onFirstTouch() {
  window.USER_IS_TOUCHING = true;
  window.removeEventListener('touchstart', onFirstTouch, false);
}, false);

const App = () => {
  const [isTouch, setisTouch] = useState(false);
  const handleOnTitleClick = () => {
    window.USER_IS_TOUCHING && setisTouch(!isTouch);
  };
  return (
    <Main>
      <Title
        onClick={() => handleOnTitleClick()}
        isTouch={isTouch}
      >
        {Text.firstName}<br />
        {Text.surname}
      </Title>
      <Background isTouch={isTouch} />
      <Wrap isTouch={isTouch}>
        <Social>
          <Link
            href={`${Portfolio}`}
            rel="noopener noreferrer"
            target="blank"
            onClick={() => ReactGA.event({
              category: 'Social',
              action: `Link.Opened:${Text.portfolio}`,
            })}>
            {Text.portfolio}
          </Link>
          <Link
            href={`${Biographie}`}
            rel="noopener noreferrer"
            target="blank"
            onClick={() => ReactGA.event({
              category: 'Social',
              action: `Link.Opened:${Text.biographie}`,
            })}>
            {Text.biographie}
          </Link>
          <Link 
            href="https://www.instagram.com/bodnar.studio"
            rel="noopener noreferrer"
            target="blank"
            onClick={() => ReactGA.event({
              category: 'Social',
              action: `Link.Opened:${Text.instagram}`,
            })}>
            {Text.instagram}
          </Link>
          <Link
            href={`mailto:${Text.mail}`}
            rel="noopener noreferrer"
            onClick={() => ReactGA.event({
              category: 'Social',
              action: `Link.Opened:${Text.mail}`,
            })}>
            {Text.mail}
          </Link>
        </Social>
        <Address
          href="https://www.google.com/maps/place/48%C2%B013'12.2%22N+16%C2%B022'34.7%22E/@48.2200591,16.3741263,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d48.2200591!4d16.376315?hl=de-AT"
          target="blank"
          rel="noopener noreferrer"
          onClick={() => ReactGA.event({
            category: 'Address',
            action: `Link.Opened:${Text.street}`,
          })}>
          {Text.address.name}<br/>
          {Text.address.street}<br/>
          {Text.address.postcode} {Text.address.city}<br/>
          {Text.address.country}<br/>
        </Address>
      </Wrap>
    </Main>
  )
}

const Main = styled.main`
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  @media only screen and (max-width: 600px)  {
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

const Background = styled.figure`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: cover;
  backface-visibility: hidden;
  color: transparent;
  background-image: url('${BACKGROUND}');
  ${({ isTouch }) => isTouch && 'filter: invert(1)'};
`;

const Wrap = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-content: stretch;
  width: 100%;
  bottom: 0;
  ${({ isTouch }) => isTouch && 'filter: invert(1)'};
  @media only screen and (max-width: 600px)  {
    position: relative;
  }
`;

const Social = styled.div`
  flex: 1;
  font-family: 'Jungka-Medium';
  font-size: 27px;
  margin: 20px;
  line-height: 1.3;
`;

const Address = styled.a`
  font-family: 'Jungka-Medium';
  font-size: 27px;
  text-decoration: none;
  margin: 20px;
  line-height: 1.3;
  &:hover {
    color: white;
    background: black;
  }
`;

const Link = styled.a`
  display: block;
  float: left;
  clear: left;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: white;
    background: black;
  }
`

const Title = styled.h1`
  margin: 0;
  margin-bottom: 110px;
  font-family: 'Jungka-Bold';
  text-transform: uppercase;
  font-size: 90px;
  line-height: .89;
  color: white;
  z-index: 10;
  ${({ isTouch }) => isTouch && 'filter: invert(1)'};
  @media only screen and (max-width: 600px)  {
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 20px;
    font-size: 75px;
  }
  &:hover {
      filter: ${({ isTouch }) => isTouch ? 'invert(0)' : 'invert(1)'}; ;
    }
    &:hover ~ figure  {
      filter: ${({ isTouch }) => isTouch ? 'invert(0)' : 'invert(1)'}; ;
    }
    &:hover ~ ${Wrap}  {
      filter: ${({ isTouch }) => isTouch ? 'invert(0)' : 'invert(1)'}; ;
    }
`;

export default App
